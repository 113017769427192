import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useMarketApi } from '../../services/market-api';
import APIAutocomplete from '../APIAutocomplete';
import ErrorDisplay from '../ErrorDisplay';
import useIntlPlus from '../hooks/IntlPlus';
import Tile from '../Tile';
import CollegeHouseMarketTile from './CollegeHouseMarketTile';
import { useMarketContext } from './MarketContext';


export interface MarketSummaryProps {
	readonly: boolean;
	editable: boolean;
}


const MarketSummary: React.FC<MarketSummaryProps> = ( props ) => {
	const { market, setMarket } = useMarketContext();
	const intl = useIntlPlus();
	const handleError = ErrorDisplay();
	const { save } = useMarketApi( handleError );

	const handleUpdate = () => {
		save( market ).then( data => {
			if ( data ) {
				setMarket( data );
			}
		} );
	};
	return (
		<div style={ { flexGrow: 1, height: 'inherit' } }>
			<Grid container width="100%" rowSpacing={ 1 } columnSpacing={ { xs: 1, sm: 1, md: 2 } } padding={ 1 }>
				<Grid item xs={ 12 } md={ 4 }>
					<Tile
						title="Market Details"
						readonly={ props.readonly }
						editable={ false }
						fields={ [
							{
								id: 'name',
								label: 'Name',
								type: 'string',
								// description: 'Common name of the project',
							},
							{
								id: 'stateCode',
								label: 'State',
								type: 'string',
								// description: 'Common name of the project',
							},
							{
								id: 'marketSurveyMarket',
								label: intl.formatMessage({id:'marketSurveyProperty', defaultMessage:'Market Survey Property Name'}),
								value: market?.marketSurveyMarket?.name,
								renderInput: ()=> <APIAutocomplete
									id="marketSurveyMarket"
									label={intl.formatMessage({id:'marketSurveyMarket', defaultMessage:'Market Survey Name'})}
									url={ '/market-survey/markets' }
									getOptionLabel={ (option) => `${option.name}` }
									isOptionEqualToValue={ (option, value) => {
										return (option?.id === value?.id);
									} }
								/>
							},
							{
								id: 'expectedOccupancyRate',
								label: 'Expected Occupancy Rate',
								value: market?.marketSurveyMarket?.expectedOccupancyRate,
								type: 'percent',
							},
							{
								id: 'surveyDay',
								label: 'Survey Day',
								value: market?.marketSurveyMarket?.surveyDay,
								type: 'string',
							}
						] }
						data={ market }
						setData={ setMarket }
						onSave={ handleUpdate }
						editProps={ { typeLabel: 'Market' } }
					/>
				</Grid>
				<Grid item xs={ 12 } md={ 4 }>
					<CollegeHouseMarketTile editable readonly={ true }/>
				</Grid>
			</Grid>
		</div>
	);
};
export default MarketSummary;

import { useMsal } from '@azure/msal-react';
import { Lock } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';
import { useMemo, useState, Fragment } from 'react';
import { Button, debounce, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import * as React from 'react';
import { States  } from '@corespaces/core-io-common';
import APIAutocomplete from '../APIAutocomplete';
import ErrorDisplay from '../ErrorDisplay';
import Select from '../Select';
import { useIntl } from 'react-intl';
import { usePropertyApi } from '../../services/property-api';
import { getApiProvider } from '../hooks/API';
const filter = createFilterOptions();
const PropertyAutocomplete = ( { readOnly, ...props} ) => {
	const { url='/properties/autocomplete', empty=[], onChange: handleChange, enableAddDialog=false } = props;

	const intl = useIntl();
	const msalContext = useMsal();

	const [propertyAutocompleteDialog, togglePropertyAutocompleteDialog] = useState(false);
	const [options, setOptions] = useState([]);
	const find = (query) => {
		if (!query) return;
		getApiProvider( msalContext ).then(api => api.getAll(`${url}`, {
			params:{
				query,
				fields:'id,name,externalPropertyId',
			}
		})
			.then(result => {
				setOptions(result?.data || empty);
			})
			.catch(error => {
				setOptions(empty);
				console.error(error);
			}))
	};
	const debouncedFind = useMemo(() => debounce(find, 200), []);
	const handleClose = (event, reason) => {
		if (reason && reason === 'backdropClick') return;
		setProperty({});
		togglePropertyAutocompleteDialog(false);
	};
	const handleError = ErrorDisplay();
	const propertyApi = usePropertyApi(handleError);
	const { saveProperty } = propertyApi;
	const [property, setProperty] = useState({});
	const dialogStyleProps = {
		margin: 'dense',
		fullWidth: true,
		variant: 'standard',
	};
	return (
		<Fragment>
			<Autocomplete
				{ ...props }
				disableClearable={readOnly}
				readOnly={readOnly}
				freeSolo
				key={ `property-autocomplete-${ props.id }`}
				filterOptions={(options, params) => {
					const filtered = filter(options, params);
					if (enableAddDialog && params.inputValue !== '') {
						filtered.push({
							inputValue: params.inputValue,
							name: `Add "${params.inputValue}"`,
						});
					}
					return filtered;
				}}
				options={options}
				getOptionLabel={(option) => {
					// e.g value selected with enter, right from the input
					if (typeof option === 'string') {
						return option;
					}
					if (option.inputValue) {
						return option.inputValue;
					}
					return option.name;
				}}
				onInputChange={(event, value, reason) => {
					if (reason === 'input') {
						debouncedFind(value);
					}
				}}
				onChange={(event, newValue) => {
					if (typeof newValue === 'string') {
						// timeout to avoid instant validation of the dialog's form.
						setTimeout(() => {
							togglePropertyAutocompleteDialog(true);
							setProperty({
								name: newValue,
							});
						});
					} else if (newValue && newValue.inputValue) {
						setTimeout(() => {
							togglePropertyAutocompleteDialog(true);
							setProperty({
								name: newValue.inputValue,
							});
						});
					} else {
						handleChange(event, newValue, 'input');
					}
				}}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				renderOption={(props, option) => <li {...props}>{option.name}</li>}
				renderInput={(params) => {
					const inputProps={
						...params?.inputProps,
						...props?.inputProps,
						endAdornment: readOnly ? <InputAdornment position="end"><Lock fontSize="small" /></InputAdornment> : undefined
					};
					return <TextField { ...props } { ...params } inputProps={inputProps} />;
				}}
			/>
			{ enableAddDialog ?
				<Dialog open={propertyAutocompleteDialog}
					onClose={handleClose}
					disableBackdropClick={true}>
					<form id='propertyAutoComplete' onSubmit={async(e)=>{
						e.preventDefault();
						e.stopPropagation();
						const result = await saveProperty(property);
						if (result.id) {
							const value = { id: result.id, name: result.name, externalPropertyId: result.externalPropertyId };
							const event={target: { value } };
							handleChange(event, value, 'input');
							togglePropertyAutocompleteDialog(false);
						}
					}}>
						<DialogTitle>
							{ `${ intl.formatMessage({ id: 'add', defaultMessage: 'Add' }) }
						${ intl.formatMessage({ id: 'property', defaultMessage: 'Community' }) }` }
						</DialogTitle>
						<DialogContent>
							<TextField
								{...dialogStyleProps}
								autoFocus
								id="name"
								value={property?.name}
								onChange={(event) =>
									setProperty({
										...property,
										name: event.target.value,
									})
								}
								label="name"
								type="text"
							/>
							{/*<TextField*/}
							{/*	{...dialogStyleProps}*/}
							{/*	id="marketingName"*/}
							{/*	label="Entrata Name"*/}
							{/*	type="string"*/}
							{/*	fullWidth={ true }*/}
							{/*	onChange={(event) =>*/}
							{/*		setProperty({*/}
							{/*			...property,*/}
							{/*			marketingName: event.target.value,*/}
							{/*		})*/}
							{/*	}*/}
							{/*/>*/}
							<Select
								{...dialogStyleProps}
								id="stateCode"
								label="State"
								options={ States }
								required
								disabled={ !!property?.id }
								inputProps={
									{ readOnly: !!property?.id }
								}
								onChange={(event)=>{setProperty({...property, stateCode:event.target.value});}}
							/>
							<TextField
								{...dialogStyleProps}
								id="city"
								label="City"
								type="string"
								required
								onChange={(event) =>
									setProperty({
										...property,
										city: event.target.value,
									})
								}
							/>
							<TextField
								{...dialogStyleProps}
								id="streetAddress"
								label="Permanent Address"
								type="string"
								required
								onChange={(event) =>
									setProperty({
										...property,
										streetAddress: event.target.value,
									})
								}
							/>
							<TextField
								{...dialogStyleProps}
								id="postalCode"
								label="Postal Code"
								type="string"
								required
								onChange={(event) =>
									setProperty({
										...property,
										postalCode: event.target.value,
									})
								}
							/>
							<APIAutocomplete
								{...dialogStyleProps}
								url="/property-types"
								id="propertyType"
								label={intl.formatMessage({id: 'property_type'})}
								loadAllOptions={ true }
								isOptionEqualToValue={ (option, value) => {
									return (option?.id === value?.id);
								} }
								onChange={(event, value) => {
									setProperty({
										...property,
										propertyType: value,
									});
								}}
							/>
							<TextField
								{...dialogStyleProps}
								id="alias"
								label="Nickname"
								type="string"
								onChange={(event) =>
									setProperty({
										...property,
										alias: event.target.value,
									})
								}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>
							<Button type="submit">Add</Button>
						</DialogActions>
					</form>
				</Dialog>
				: null }
		</Fragment>);
};

PropertyAutocomplete.propTypes = {
	id: PropTypes.string,
	url: PropTypes.string,
	empty: PropTypes.array,
	readOnly: PropTypes.bool,
	onChange: PropTypes.func,
	enableAddDialog: PropTypes.bool
};


export default PropertyAutocomplete;

import { AppConfigurationClient } from '@azure/app-configuration';
import { AccessToken, TokenCredential } from '@azure/identity';
import { useMsal } from '@azure/msal-react';
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { reAuth } from '../../services/common/api-utils';

interface FeatureFlagContext {
	[key: string]: boolean;
}

interface FeatureFlagsContextType {
	featureFlags: FeatureFlagContext;
	loading: boolean;
	error: Error | null;
}

const APP_CONFIG_URL = window.env.APP_CONFIG_URL;

const DEFAULT_VALUE:FeatureFlagsContextType = {
	featureFlags: {},
	loading: false,
	error: null,
};

const FeatureFlagsContext = createContext<FeatureFlagsContextType>( DEFAULT_VALUE );

interface FeatureFlagsProviderProps {
	children: ReactNode;
}

export const FeatureFlagsProvider: React.FC<FeatureFlagsProviderProps> = ({ children }) => {
	const [featureFlags, setFeatureFlags] = useState<FeatureFlagContext>({});
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);
	const msalContext = useMsal();

	useEffect(() => {
		const fetchFeatureFlags = async () => {
			setLoading(true);
			try {
				if (APP_CONFIG_URL) {
					const tokenCredential: TokenCredential = {
						getToken: async ( scopes: string[] ) => {
							console.log( 'scopes', scopes );
							const token = await ( await reAuth( msalContext, scopes ) )();
							return { token } as AccessToken;
						}
					};
					const client = new AppConfigurationClient( APP_CONFIG_URL, tokenCredential, undefined );
					// const client = new AppConfigurationClient(`Endpoint=${ENDPOINT_URL};Id=kYmc;Secret=${APP_CONFIG_SECRET}`);
					const settings = client.listConfigurationSettings(
						{
							keyFilter: '.appconfig.featureflag/*',
							labelFilter: window.env.apiEnv ?? 'production',
						} );
					const flags: FeatureFlagContext = {};
					for await ( const setting of settings ) {
						const value = setting?.value ? JSON.parse( setting.value ) : undefined;
						flags[value.id] = value.enabled;
					}
					console.debug( 'FeatureFlags: ', flags );
					setFeatureFlags( flags );
				}
			} catch (err: any) {
				console.error('Error fetching feature flags:', err);
				setError(err);
			} finally {
				setLoading(false);
			}
		};
		fetchFeatureFlags();
		const interval = setInterval( fetchFeatureFlags, 1000*60*60);
		return () => clearTimeout(interval);
	}, [msalContext]);

	return (
		<FeatureFlagsContext.Provider value={{ featureFlags, loading, error }}>
			{children}
		</FeatureFlagsContext.Provider>
	);
};

export const useFeatureFlags = (): FeatureFlagContext => {
	const context = useContext(FeatureFlagsContext);
	if (!context) {
		throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
	}
	return context.featureFlags;
};

import React from 'react';
import { Link, Typography, TypographyProps } from '@mui/material';
import useIntlPlus from './hooks/IntlPlus';
import Box from '@mui/material/Box';
import CopyButton from './CopyButton';

const defaultSx = { lineHeight:'1.5rem', fontFamily: '"Roboto","Helvetica","Arial","sans-serif"', fontSize:'.875rem' };
const defaultLabelSx = { fontWeight:'bold', ...defaultSx };

interface DisplayFieldProps extends TypographyProps {
  id: string,
  label: string,
  value: any,
  type?: string,
  title?: string,
  masked?: boolean,
  href?:string,
  copy?:boolean,
  readOnly?: boolean
}
const DisplayField: React.FC<DisplayFieldProps> = (props) =>{
	const { id, label, value, href, type='string', title,
		sx,
		children,
		masked=false,
		color,
		copy=false,
		...rest } = props;
	const intl = useIntlPlus();
	const formattedValue = masked ? '********' : intl.formatValue(type, value);

	return (<Box key={ id } title={title ?? formattedValue} style={{display:'flex', flexWrap: 'wrap', maxWidth:'100%', whiteSpace: 'normal' }}>
		<Typography {...rest} sx={{ ...sx, ...defaultLabelSx, marginRight: 1, whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipses' } }>{ intl.formatMessage({id:id, defaultMessage: label}) }:</Typography>
		<Typography {...rest} sx={{ flex: 1, whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipses', textAlign:'right', ...sx, ...defaultSx }} color={color}>{ href || type === 'link' ? <Link href={href ?? value} target={label}>{ formattedValue }</Link> : formattedValue }</Typography>
		{ copy && <CopyButton label={ label } value={ value }/> }
		{ children }
	</Box>);
};

export default DisplayField;

//@ts-ignore
import { States } from '@corespaces/core-io-common';
import { TextField } from '@mui/material';
import * as React from 'react';
import { EditDataDialog } from '../EditDataDialog';
import { useIntl } from 'react-intl';
import Select from '../Select';
import { Market } from './types';
import APIAutocomplete from '../APIAutocomplete';


interface MarketEditProps {
	open: boolean,
	onClose: any,
	onSave: any,
	market: Market
}

export const MarketEdit: React.FC<MarketEditProps> = ( { open, onClose, onSave, market } ) => {
	const intl = useIntl();
	return (
		<EditDataDialog
			open={ open }
			data={ market }
			onClose={ onClose }
			onSave={ onSave }
			typeLabel={ intl.formatMessage( { id: 'market', defaultMessage: 'Market' } ) }
			disableAuditNote={ true }
		>
			<TextField
				id="name"
				label="Name"
				type="string"
				required
			/>
			<Select
				id="stateCode"
				label="State"
				options={ States }
				required
			/>
			<APIAutocomplete
				label={'College House Market'}
				id="collegeHouseMarket"
				url={ '/college-house/markets' }
				getOptionLabel={ ( option ) => `${ option.city }, ${ option.stateAbbr }` }
				isOptionEqualToValue={ ( option, value ) => {
					return ( option?.marketKey === value?.marketKey );
				} }
			/>
			<APIAutocomplete
				id={'marketSurveyMarket'}
				label={intl.formatMessage({id:'marketSurveyMarket', defaultMessage:'Market Survey Market'})}
				url={ '/market-survey/markets' }
				getOptionLabel={ (option) => `${option.name}` }
				isOptionEqualToValue={ (option, value) => {
					return (option?.id === value?.id);
				} }
			/>
		</EditDataDialog> );
};

export default MarketEdit;

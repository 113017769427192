import * as React from 'react';
import { useIntl } from 'react-intl';
import Page from 'material-ui-shell/lib/containers/Page';
import {
	DataGridPremium,
	GridActionsCellItem,
	GridColDef,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarExport,
	GridToolbarFilterButton,
	useGridApiContext
} from '@mui/x-data-grid-premium';
import {
	Button,
	LinearProgress,
	Link
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ErrorDisplay from '../ErrorDisplay';
import { useSnackbar } from 'notistack';
import {
	Clear,
	Refresh
} from '@mui/icons-material';
import PageTitle from '../hooks/PageTitle';
import MarketEdit from './MarketEdit';
import useLocalStorage from '../hooks/LocalStorage';
// @ts-ignore
import { States } from '@corespaces/core-io-common';
import GlobalSearch from '../GlobalSearch';
import Select from '../Select';
import { Market } from './types';
import { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../AbilityContext.js';

const defaultSortModel = [ { field: 'name', sort: 'asc' } ];
const defaultFilterModel = { items: [] };
const defaultColumnVisibilityModel = {
	id: false,
	name: true,
	alias: true,
	createdAt: false,
	updatedAt: false
};

interface MarketGridProps {
	data: [],
	setRefresh: Function,
	api: { remove: Function, save: Function, isLoading: boolean, getOne: Function }
}

export const MarketGrid: React.FC<MarketGridProps> = ( { data, setRefresh, api } ) => {
	const { enqueueSnackbar } = useSnackbar();
	const handleError = ErrorDisplay();
	const intl = useIntl();
	const { remove, save, getOne } = api;
	const [ market, setMarket ] = React.useState<Market>({} as Market);
	const getDate = ( params: any ) => params.value ? new Date( params.value ) : params.value;
	const formatDate = ( params: any ) => {
		if ( !params.value ) {
			return params.value;
		}
		return intl.formatDate( params.value, {
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
			hour: 'numeric',
			minute: 'numeric'
		} );
	};
	PageTitle( { id: 'markets', defaultTitle: 'Markets' } );
	const [ gridState, setGridState ] = useLocalStorage( 'market-grid-model', {
		columns: { columnVisibilityModel: defaultColumnVisibilityModel },
		sorting: { sortModel: defaultSortModel },
		filtering: { filterModel: defaultFilterModel },
		pinnedColumns: {
			right: [ 'updatedAt', 'actions' ],
			left: []
		}
	} );
	const ability = useAbility( AbilityContext );
	const [ open, setOpen ] = React.useState( false );
	const [ canCreate ] = React.useState( ability.can( 'create', 'Market' ) );
	const [ canEdit ] = React.useState( ability.can( 'update', 'Market' ) );
	const [ canDelete ] = React.useState( ability.can( 'delete', 'Market' ) );
	const editRow = React.useCallback(
		( params: any ) => () => {
			setMarket(params.row);
			if (params.row?.id) {
				getOne( params.row?.id )
					.then((res:Market)=> {
						setMarket( res );
						setOpen( true );
					});
			}
		},
		[]
	);
	const deleteRow = React.useCallback(
		( params: any ) => async () => {
			try {
				const data = await remove( params.row.id );
				if ( data && data.deleted ) {
					setRefresh( true );
					enqueueSnackbar( `Deleted ${ data.deleted || 0 } Markets`, {
						variant: 'info',
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'center'
						}
					} );
				}
			}
			catch ( e ) {
				await handleError( e );
			}
		},
		[ remove, enqueueSnackbar, handleError, setRefresh ]
	);
	const columns: GridColDef<Market>[] = useMemo( () => [
		{
			field: 'actions', type: 'actions', maxWidth: 20, minWidth: 20, width: 20,
			getActions: ( params: any ) => [
				<GridActionsCellItem
					icon={ <EditIcon/> } onClick={ editRow( params ) } label="Edit" showInMenu
					disabled={ !canEdit }/>,
				<GridActionsCellItem
					icon={ <DeleteIcon/> } onClick={ deleteRow( params ) } label="Delete" showInMenu
					disabled={ !canDelete }/>
			]
		},
		{
			field: 'name', headerName: 'Name', minWidth: 300, description: 'Market Full Name',
			renderCell: ( params ) => (
				<Link
					id={ `market_name_link${ params.row.id }` } component={ RouterLink }
					to={ `/markets/${ params.row.id }` }>{ params.value }</Link>
			), groupable: false
		},
		{
			field: 'stateCode', headerName: 'State', minWidth: 80, editable: true, groupable: true,
			description: 'State Code',
			renderEditCell: ( props: any ) => ( <Select { ...props } options={ States }/> )
		},
		{
			field: 'universities',
			headerName: intl.formatMessage( { id: 'university', defaultMessage: 'Universities' } ),
			description: intl.formatMessage( {
				id: 'university_alias',
				defaultMessage: 'Universities in market'
			} ),
			minWidth: 220, editable: false, groupable: false,
			valueFormatter: ( p ) => p.value?.map( ( u: any ) => u.alias ?? u.name ).join( ', ' )
		},
		{ field: 'externalId', headerName: 'CH Id', minWidth: 30, description: 'College House Id',
			renderCell: ( p ) => (p.value && <Link title={'Open In College House'} target={p.value} href={ `${window.env.collegeHouseUrl}/markets/${ p.value }` }>{ p.value }</Link>)
		},
		{ field: 'marketSurveyId', headerName: 'MS Id', minWidth: 30, description: 'Market Survey Id' },
		// { field: 'collegeHouseMarket', headerName: 'College House Market', minWidth: 300, description: 'College House',
		// 	renderCell: ( p ) => (p.value && <Link target={p.value?.name} href={ `${window.env.collegeHouseUrl}/markets/${ p.value?.marketKey }` }>{ p.value?.city }, {p.value?.stateAbbr }</Link>)
		// },
		// { field: 'marketSurveyMarket', minWidth: 300,
		// 	headerName: intl.formatMessage({id:'marketSurveyMarket', defaultMessage:'Market Survey'}),
		// 	renderCell: ( p ) => p.value?.name,
		// },
		{
			field: 'createdAt',
			headerName: 'Created',
			minWidth: 200,
			type: 'dateTime',
			valueFormatter: formatDate,
			valueGetter: getDate
		},
		// { field: 'createdBy', headerName: 'Updated By', minWidth: 200, type: 'string',
		// 	valueGetter: (v:any) => v?.value?.name, editable: false, groupable: false
		// },
		{
			field: 'updatedAt',
			headerName: 'Updated',
			minWidth: 200,
			type: 'dateTime',
			valueFormatter: formatDate,
			valueGetter: getDate
		},
		// { field: 'updatedBy', headerName: 'Updated By', minWidth: 200, type: 'string',
		// 	valueGetter: (v:any) => v?.value?.name, editable: false, groupable: false
		// },
	], [] );
	const handleClickAdd = async () => {
		setOpen( true );
	};
	const handleClose = ( saved: boolean ) => {
		setOpen( false );
		setMarket( {} as Market );
		setRefresh( saved );
	};

	function CustomToolbar() {
		const apiRef = useGridApiContext();
		return (
			<GridToolbarContainer>
				<Button
					size="small"
					startIcon={ <AddIcon/> }
					onClick={ handleClickAdd }
					disabled={ !canCreate }
					title={ intl.formatMessage( { id: 'add_market', defaultMessage: 'Add Market' } ) }
				>
					{ intl.formatMessage( { id: 'add', defaultMessage: 'Add' } ) }
				</Button>
				<Button
					size="small"
					startIcon={ <Refresh/> }
					onClick={ () => {
						setRefresh( true );
					} }
					title={ intl.formatMessage( { id: 'refresh-grid', defaultMessage: 'Refresh Data' } ) }
				>
					{ intl.formatMessage( { id: 'refresh', defaultMessage: 'Refresh' } ) }
				</Button>
				<GridToolbarColumnsButton/>
				<GridToolbarFilterButton/>
				<GridToolbarExport/>
				<Button
					size="small"
					startIcon={ <Clear/> }
					onClick={ () => {
						apiRef.current.restoreState( {
							...gridState,
							columns: { columnVisibilityModel: defaultColumnVisibilityModel },
							sorting: { sortModel: defaultSortModel },
							filter: { filterModel: defaultFilterModel },
							pinnedColumns: {}
						} );
					} }
					title={ intl.formatMessage( { id: 'reset-grid-config', defaultMessage: 'Reset Grid Settings' } ) }
				>
					{ intl.formatMessage( { id: 'reset', defaultMessage: 'Reset' } ) }
				</Button>
			</GridToolbarContainer>
		);
	}

	return (
		<Page
			pageTitle={ intl.formatMessage( { id: 'markets', defaultMessage: 'Markets' } ) }
			isLoading={ api.isLoading }
			appBarContent={
				<GlobalSearch/>
			}
		>
			<div style={ { height: '99%', width: '99%', alignContent: 'space-around' } }>
				<MarketEdit
					open={ open }
					onClose={ handleClose }
					market={ market }
					onSave={ ( market: Partial<Market>, config:any )=> {
						if ( Object.hasOwn( market,'marketSurveyMarket') ) {
							market.marketSurveyId = market.marketSurveyMarket?.id ?? null;
							delete market.marketSurveyMarket;
						}
						if ( Object.hasOwn(market, 'collegeHouseMarket' )) {
							market.externalId = market.collegeHouseMarket?.marketKey ?? null;
							delete market.collegeHouseMarket;
						}
						return save(market);
					} }
				/>
				<DataGridPremium
					rows={ data }
					columns={ columns }
					pagination
					disableRowGrouping={ true }
					checkboxSelection={ false }
					checkboxSelectionVisibleOnly={ false }
					initialState={ gridState }
					onStateChange={ ( state ) => setGridState( state ) }
					editMode="row"
					sortingOrder={ [ 'desc', 'asc' ] }
					slots={ {
						toolbar: CustomToolbar,
						loadingOverlay: LinearProgress,
					} }
				/>
			</div>
		</Page>
	);
};
